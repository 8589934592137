<template>
	<modal class="NoxModalErrors" name="NoxModalErrors" modal-width="550" :width="550" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'savePhoenixSettings'" v-html="$store.getters.getLanguageText('5.20', 0)"></span>
					<span v-else-if="noxType == 'closePhoenixSettings'" v-html="$store.getters.getLanguageText('5.20', 2)"></span>
					<span v-else-if="noxType == 'closeAddBotDemo'" v-html="$store.getters.getLanguageText('5.20', (noxDetails.step > 5 ? 10 : 8))"></span>
					<span v-else-if="noxType == 'closeStartBotDemo'" v-html="$store.getters.getLanguageText('5.20', (noxDetails.step > 5 ? 10 : 8))"></span>
					<span v-else-if="noxType == 'closeStartBotIntro'" v-html="$store.getters.getLanguageText('5.20', (noxDetails.step > 5 ? 6 : 4))"></span>
					<span v-else-if="noxType == 'enableUnlimitedAveraging'" v-html="$store.getters.getLanguageText('5.20', (noxIndex ? 14 : 12), { symbol: ((Object.keys($store.state.noxMTabs).length && $store.state.noxMTabs['phoenix_settings'] !== undefined) ? $store.state.noxMTabs['phoenix_settings'].titles[noxIndex] : '') })"></span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="noxType == 'savePhoenixSettings'" v-html="$store.getters.getLanguageText('5.20', 1, { options: [Math.abs([0.6, 0.5, 'MAX', 'BO'].indexOf(noxDetails['division_of_lengths']))], conditions: [Number([0.6, 0.5, 'MAX', 'BO'].includes(noxDetails['division_of_lengths'])), Number(noxDetails['trade_on_holidays']), Number(noxDetails['full_averaging'])], name_x3: $store.state.noxSystemSettings.package_name_x3, name_x4: $store.state.noxSystemSettings.package_name_x4, name_x5: $store.state.noxSystemSettings.package_name_x5, name_x6: $store.state.noxSystemSettings.package_name_x6 })"></div>
					<div v-else-if="noxType == 'closePhoenixSettings'" v-html="$store.getters.getLanguageText('5.20', 3)"></div>
					<div v-else-if="noxType == 'closeAddBotDemo'" v-html="$store.getters.getLanguageText('5.20', (noxDetails.step > 5 ? 11 : 9))"></div>
					<div v-else-if="noxType == 'closeStartBotDemo'" v-html="$store.getters.getLanguageText('5.20', (noxDetails.step > 5 ? 11 : 9))"></div>
					<div v-else-if="noxType == 'closeStartBotIntro'" v-html="$store.getters.getLanguageText('5.20', (noxDetails.step > 5 ? 7 : 5))"></div>
					<div v-else-if="noxType == 'enableUnlimitedAveraging'" v-html="$store.getters.getLanguageText('5.20', (noxIndex ? 15 : 13), { symbol: ((Object.keys($store.state.noxMTabs).length && $store.state.noxMTabs['phoenix_settings'] !== undefined) ? $store.state.noxMTabs['phoenix_settings'].titles[noxIndex] : '') })"></div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common red" @click="axios" v-if="['closePhoenixSettings', 'closeAddBotDemo', 'closeStartBotDemo', 'closeStartBotIntro'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', (noxType == 'closePhoenixSettings' ? 145 : (['closeAddBotDemo', 'closeStartBotDemo'].includes(noxType) ? 163 : 151)))"></button>
					<button type="button" class="nox_button common green" @click="close" v-if="['closePhoenixSettings', 'closeAddBotDemo', 'closeStartBotDemo', 'closeStartBotIntro'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', (noxType == 'closePhoenixSettings' ? 146 : (['closeAddBotDemo', 'closeStartBotDemo'].includes(noxType) ? 164 : 152)))"></button>
					<button type="button" class="nox_button common green" @click="close" v-else-if="noxType == 'enableUnlimitedAveraging'" v-html="$store.getters.getLanguageText('1.1', 10)"></button>
					<button type="button" class="nox_button common" @click="close" v-else v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-if="noxType == 'enableUnlimitedAveraging'" v-html="$store.getters.getLanguageText('1.1', 179)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxDetails: {},
			noxIsModalClose: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxDetails = event.params.details;
				this.noxIsModalClose = false;
				this.noxIsLoading = false;
				this.noxIsError = false;
			},
			resetAlerts: function() {
				this.noxAlert = '';
			},
			getError1: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 8); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 88); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.5', 89); }
				else             { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			getError2: function(i) {

				this.noxIsError = true;

				if (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.17', 28); }
				else        { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (_this.noxType == 'closePhoenixSettings') {
						_this.noxIsModalClose = true;
					}
					else if (['closeAddBotDemo', 'closeStartBotDemo', 'closeStartBotIntro'].includes(_this.noxType)) {
						if (_this.noxId == undefined || _this.noxIndex == undefined || (_this.noxDetails.step < (_this.noxType == 'closeStartBotIntro' ? 6 : 3))) {
							_this.noxIsModalClose = true;
						}
						else {
							_this.noxIsLoading = true;
							config.url = '/v2/account/bots/' + (_this.noxType == 'closeStartBotIntro' ? 'nox_intro' : 'nox_demo') + '/installation/stop';
							config.data = { id: _this.noxId };
							config.method = (_this.noxType == 'closeAddBotDemo' ? 'post' : 'patch');
						}
					}
					else if (_this.noxType == 'enableUnlimitedAveraging') {
						_this.$store.state.noxMTabs['phoenix_settings'].blocks[_this.noxIndex]['unlimited_averaging'] = 1; _this.close(); return false;
					}
					if (_this.noxIsModalClose) {
						_this.close(); _this.$store.state.noxIsModalClose = true; _this.$modal.hide('NoxModalBotSettings'); return false;
					}

					if (!_this.noxIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (['closeAddBotDemo', 'closeStartBotDemo', 'closeStartBotIntro'].includes(_this.noxType)) {
									if (data.data.account_data !== undefined) {
										_this.$parent.$parent.$parent.initAccountData(data.data.account_data);
									}
									if (_this.noxType == 'closeAddBotDemo') {
										_this.$parent.$parent.$parent.initBotsData([], _this.noxIndex);
									}
									else {
										if (data.data.bot_data !== undefined && data.data.bot_data !== null) {
											_this.$parent.$parent.$parent.initBotsData(data.data.bot_data, _this.noxIndex);
										}
									}
								}
								_this.close(); _this.$store.state.noxIsModalClose = true; _this.$modal.hide('NoxModalBotSettings');
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['closeAddBotDemo', 'closeStartBotDemo', 'closeStartBotIntro'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError1(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError1(2); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['closeAddBotDemo', 'closeStartBotDemo', 'closeStartBotIntro'].includes(_this.noxType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError1(3); }
									}
								}
								else if (data.response.status == 409) {
									if (['closeAddBotDemo', 'closeStartBotDemo', 'closeStartBotIntro'].includes(_this.noxType)) {
										if      (data.response.data.error == 'BOT_CANNOT_BE_RESET') { _this.noxAlert = _this.getError1(4); }
										else if (data.response.data.error == 'BOT_CANNOT_BE_DELETED') { _this.noxAlert = _this.getError1(5); }
										else if (data.response.data.error == 'SERVER_NOT_DELETED') { _this.noxAlert = _this.getError2(1); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError1(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalErrors');
			}
		}
	}
</script>
